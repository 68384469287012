/* Global styles... */

.navbar-container {
  margin-top: 30px;
  text-align: center;
  position: relative;
}

ul {
  list-style-type: none;
  font-weight: bold;
  padding: 0;
  margin: 0;
  display: inline-block;
}

li {
  display: inline-block;
  margin-right: 20px;
}

.NavLink {
  text-decoration: none;
  color: #000000;
}

.NavLink:hover {
  color: #df0741;
}

.active {
  color: #df0741;
  text-decoration: none;
}

.icon {
  color: black;
  vertical-align: middle;
}

.icons {
  display: inline-block;
  margin-left: 75px;
}

.logo {
  text-decoration: none;
  font-size: x-large;
  color: #000000;
  display: inline-block;
  margin-right: 75px;
}

.waters {
  position: relative;
}

.waters-menu {
  display: none;
  position: absolute;
  text-align: left;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
  width: 100%;
}

.waters:hover .waters-menu {
  display: block;
  width: 175px;
}

.waters-menu a {
  display: block;
  text-decoration: none;
  padding: 10px;
  color: #000;
}

.waters-menu a:hover {
  text-decoration: none;
  color: #df0741;
}

@media (max-width: 768px) {
  .menu-toggle {
    display: block;
    cursor: pointer;
    position: absolute;
    top: 30px; /* Adjust this value based on your design */
    right: 10px;
    font-size: 24px;
  }

  ul {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px; /* Adjust this value based on your design */
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1;
    width: 100%;
  }

  .menu-open ul {
    display: flex;
  }

  li {
    margin: 0;
    text-align: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
}

/* Additional styles to hide the menu toggle when the media query is not true */
@media (min-width: 769px) {
  .menu-toggle {
    display: none;
  }
}