.page-container {
  margin-left: 300px;
  margin-right: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

h1 {
  margin-bottom: 20px;
}

.members {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.member {
  margin: 20px;
  font-size: large;
}

.memberRole {
  margin-top: 10px;
  color: #df0741;
  font-weight: bold;
}

.memberName {
  font-weight: bold;
  margin-top: 10px;
}

.memberEmail {
  margin-top: 10px;
}

.memberPhone {
  margin-bottom: 20px;
}

/* Media query for iPhones or devices with a max-width of 600px */
@media only screen and (max-width: 600px) {
  .page-container {
    margin-left: 10px !important;
    margin-right: 10px !important;
    align-items: flex-start !important;
  }

  .members {
    justify-content: flex-start;
  }

  .member {
    margin: 10px;
    font-size: medium;
  }
}
