.page-container {
  margin-left: 300px;
  margin-right: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

h1 {
  margin-bottom: 20px;
}

/* Media query for mobile devices */
@media only screen and (max-width: 600px) {
  .page-container {
    margin-left: 10px;
    margin-right: 10px;
    align-items: flex-start;
  }
}

/* Add more styles as needed for other elements based on your design */
